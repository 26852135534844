<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Tools</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >Barcode</li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Generated Barcode</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 tx-right">
        <button
          type="button"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="print"
        >Print</button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="card">
   <!--  <div class="modal-dialog-centered center-box">
      <div class="modal-body bg-theam-secondary row"> -->
    <div
      v-if="barcodeValue.printer_type == 'a4_printer'"
      class="row p-3 mx-3"
      id="printMe"
    >
      <div
        class="col-4 a4-print p-1 px-0"
        v-for="n in parseInt(barcodeValue.print_count)"
        :key="n"
      >
        <barcode
          class="barcode text-center"
          v-bind:value="barcodeValue.barcode"
          height="35"
          width="1"
          font-size="15px"
        >
          Show this if the rendering fails.
        </barcode>
      </div>
    </div>
    <div
      v-if="barcodeValue.printer_type == 'barcode_printer'"
      class="row px-3"
      id="printMe"
    >
      <div
        class="col-md-12 p-0"
        v-for="n in parseInt(barcodeValue.print_count)"
        :key="n"
      >
        <barcode
          class="barcode text-center barcode-size"
          v-bind:value="barcodeValue.barcode"
          height="30"
          width="1"
          font-size="15px"
        >
          Show this if the rendering fails.
        </barcode>
      </div>
    </div>
     <!-- </div> -->
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VueBarcode from "vue-barcode";
import { mapGetters } from "vuex";
import VueHtmlToPaper from "vue-html-to-paper";

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "./assets/css/barcode.css",
  ],
};

Vue.use(VueHtmlToPaper, options);

export default {
  components: {
    barcode: VueBarcode,
  },
  data() {
    return {
      barcodeValue: "",
    };
  },
  computed: {
    ...mapGetters(["barcodeData", "dataLists"]),
  },

  methods: {
    print() {
      // Pass the element id here
      window.print();
      //this.$htmlToPaper("printMe");
    },
  },
  beforeMount() {
    this.barcodeValue = this.barcodeData;
    console.log(this.barcodeValue);
  },
  watch: {},
};
</script>
<style>
.vue-barcode-element {
  height: auto !important;
  width: 100% !important;
}

@media print {
  /* html,
  body {
    width: 210mm;
    height: 297mm;
  } */
  .card {
    border: none;
  }
}
</style>
